<template>
  <el-input
    v-loading="isLoading"
    class="cvs-select cursor-pointer"
    :value="observeValue"
    placeholder="若無填寫將退回原寄貨門市"
    suffix-icon="el-icon-plus"
    readonly
    @click.native="onSelectCVS"
  />
</template>

<script>
import { GetCVSMap, GetSelectCVS } from '@/api/ecommerce/delivery'
export default {
  name: 'CvsSelect',
  props: {
    value: { type: String },
    shopId: { type: String },
    cvsType: { type: String },
    configID: { type: String },
  },
  data () {
    return {
      token: '',
      link: '',
      isLoading: false,
      ecpayWindow: null,
      timer: null,
      cvsInfo: {
        CVSStoreID: '',
        CVSStoreName: '',
        CVSAddress: '',
        CVSTelephone: '',
      },
    }
  },
  computed: {
    observeValue: {
      get () {
        const { CVSStoreName } = this.cvsInfo
        if (!this.value) return ''
        return `${this.value} ${CVSStoreName}`
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    cvsType: {
      handler (value) {
        if (!value) return
        this.GetCVSLink()
      },
    },
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    onSelectCVS () {
      const { cvsType: subType, link } = this

      if (!subType) return this.$message.error('請先選擇物流類型')
      if (!link) {
        this.$message.error('取得門市連結，請重新點選')
        this.GetCVSLink()
        return
      }

      if (this.ecpayWindow && !this.ecpayWindow.closed) {
        this.$message.error('你已開啟選擇門市代號視窗')
        this.ecpayWindow.close()
        this.ecpayWindow = null
      }
      clearInterval(this.timer)
      // const windowSize = 'fullscreen=yes,toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0'
      // this.ecpayWindow = window.open(link, '_blank', windowSize)
      this.ecpayWindow = window.open(link)
      this.timer = setInterval(this.checkDeliveryStatus, 1000)
    },
    async checkDeliveryStatus () {
      if (!this.ecpayWindow) return
      if (!this.ecpayWindow.closed) return
      clearInterval(this.timer)
      const { shopId, token } = this
      const [res, error] = await GetSelectCVS({
        shopId,
        token,
      })
      if (error) return this.$message.error(error)
      if (!res.CVSStoreID) return
      this.cvsInfo = res
      this.observeValue = res.CVSStoreID
    },
    fullscreenECPayWindow () {
      if (!this.ecpayWindow) return
      try {
        this.ecpayWindow.moveTo(0, 0)
        this.ecpayWindow.resizeTo(screen.width, screen.height)
      } catch (err) {
        console.log(err)
      }
    },
    async GetCVSLink () {
      this.isLoading = true
      const { cvsType: subType, configID } = this
      const [res, error] = await GetCVSMap({
        shopId: this.shopId,
        subType,
        EcommerceDeliveryConfigId: configID,
      })
      this.isLoading = false
      if (error) return this.$message.error(error)
      const { token, link } = res
      this.token = token
      this.link = link
    },
  },
}
</script>

<style lang="scss">
.cvs-select {
  .el-input__inner {
    @apply cursor-pointer;
  }
}
</style>
