<template>
  <div class="delivery-thrid-party-setting">
    <section>
      <div class="mb-[24px]">
        <div class="flex justify-end">
          <el-button class="min-w-[100px]" type="primary" @click="onOpenDialog('create')">新增</el-button>
        </div>
      </div>
      <el-table
        v-loading="isLoading"
        :data="configData"
      >
        <EmptyBlock slot="empty" />
        <el-table-column label="種類" width="auto" align="center">
          <template slot-scope="scope">
            {{ configName[scope.row.type] }} ({{ scope.row.config.merchantID }})
          </template>
        </el-table-column>
        <el-table-column label="操作" width="auto" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              hideEdit
              @delete="onDelete(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :curPage.sync="search.page"
        :pageLimit="search.limit"
        :total="configCount"
        @pageChange="refresh"
      />
    </section>
    <el-dialog
      v-loading="isLoading"
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @closed="onCloseDialog"
    >
      <section>
        <el-form
          ref="form"
          :model="formData"
          label-position="top"
          :rules="formRules"
        >
          <el-form-item label="種類" prop="type">
            <el-select v-model="formData.type" placeholder="">
              <el-option
                v-for="(type, index) in configList"
                :key="`type-${index}`"
                :label="type.name"
                :value="type.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="MerchantID" prop="merchantID">
            <el-input v-model="formData.merchantID" placeholder="請輸入 MerchantID" />
          </el-form-item>
          <el-form-item label="HashKey" prop="hashKey">
            <el-input v-model="formData.hashKey" placeholder="請輸入 HashKey" />
          </el-form-item>
          <el-form-item label="HashIV" prop="hashIV">
            <el-input v-model="formData.hashIV" placeholder="請輸入 HashIV" />
          </el-form-item>
        </el-form>
      </section>
      <div slot="footer">
        <el-button plain @click="showDialog = false">
          取消
        </el-button>
        <el-button type="primary" @click="onSubmit">
          {{ dialogMode === 'create' ? '新增' : '儲存' }}
        </el-button>
      </div>
    </el-dialog>
    <DeleteDialog
      v-if="showDeleteDialog"
      title="提示"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="showDeleteDialog = false"
      @delete="onConfirmDelete"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { dialogTitle } from '@/utils/dialog'
import { noEmptyRules } from '@/validation/index'
import formUtils from '@/utils/form'
import { deliveryConfigList, deliveryConfigOptionsName, deliveryConfigOptions } from '@/config/ecommerce.js'
import {
  CreateDeliveryThirdPartyConfig,
  DeleteDeliveryThirdPartyConfig,
  GetDeliveryThirdPartyConfigs,
  GetDeliveryThirdPartyConfigsCount,
} from '@/api/ecommerce/delivery'

export default {
  name: 'DeliveryThirdPartySetting',
  data: () => ({
    isLoading: false,
    search: {
      name: '',
      page: 1,
      limit: 10,
    },
    configData: [],
    configCount: 0,
    showDialog: false,
    showDeleteDialog: false,
    selectRow: null,
    dialogMode: '', // create/update
    formData: {
      id: '',
      type: '',
      merchantID: '',
      hashKey: '',
      hashIV: '',
    },
    formRules: {
      type: [noEmptyRules()],
      merchantID: [noEmptyRules()],
      hashKey: [noEmptyRules()],
      hashIV: [noEmptyRules()],
    },
    configName: { ...deliveryConfigOptionsName },
    configList: deliveryConfigList,
  }),
  computed: {
    ...mapGetters(['shop']),
    dialogTitle () {
      return dialogTitle(this.dialogMode, {
        create: '新增串接種類',
      })
    },
  },
  mounted () {
    this.refresh()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    refresh () {
      this.isLoading = true
      Promise.all([
        this.getDeliveryThirdPartyConfigs(),
        this.getDeliveryThirdPartyConfigsCount(),
      ]).catch(() => {})
      this.$emit('refresh')
      this.isLoading = false
    },
    onOpenDialog (mode, row) {
      this.dialogMode = mode
      this.formData.type = deliveryConfigOptions.ecpay
      if (row) {
        this.formData.id = row.id
        this.formData.merchantID = row.config.merchantID
        this.formData.hashKey = row.config.hashKey
        this.formData.hashIV = row.config.hashIV
      }
      this.showDialog = true
    },
    onDelete (row) {
      this.showDeleteDialog = true
      this.selectRow = row
    },
    async onSubmit () {
      const valid = await formUtils.checkForm(this.$refs.form)
      if (!valid) return

      const type = this.dialogMode
      this.isLoading = true
      if (type === 'create') await this.createDeliveryConfig()
      this.isLoading = false
    },
    async getDeliveryThirdPartyConfigs () {
      const { page = 1, limit = 10 } = this.search
      const start = (page - 1) * limit
      const [res, error] = await GetDeliveryThirdPartyConfigs({
        shopId: this.shop,
        start,
        limit,
      })
      if (error) return this.$message.error(error)
      this.configData = res
      return true
    },
    async getDeliveryThirdPartyConfigsCount () {
      const [res, error] = await GetDeliveryThirdPartyConfigsCount({
        shopId: this.shop,
      })
      if (error) return this.$message.error(error)
      this.configCount = res.count
      return true
    },
    async createDeliveryConfig () {
      const [res, error] = await CreateDeliveryThirdPartyConfig({
        shopId: this.shop,
        ...this.formData,
      })
      if (error) return this.$message.error(error)
      this.showDialog = false
      this.$message.success('更新成功')
      await this.refresh()
    },
    async onConfirmDelete () {
      const [res, error] = await DeleteDeliveryThirdPartyConfig({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (error) return this.$message.error(error)
      console.log(res)
      this.$message.success('刪除成功')
      this.showDeleteDialog = false
      await this.refresh()
    },
    onCloseDialog () {
      formUtils.resetForm(this.$refs.form)
    },
  },
}
</script>

<style lang="scss">
.delivery-thrid-party-setting {
}
</style>
