import { admin2 } from '@/api/instance'
import configuration from '@/configuration'

// => 創建配送方式
export const CreateDelivery = async ({
  shopId,
  name,
  description,
  price,
  type,
  subType,
  senderName,
  senderCellPhone,
  returnStoreID,
  EcommerceDeliveryConfigId,
  enable,
  allowCollection,
  enablePickupBranch,
  enablePickupTime,
  requireOrderAfter,
  requireOrderBefore,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceDelivery`,
    data: {
      name,
      description,
      price,
      type,
      subType,
      senderName,
      senderCellPhone,
      returnStoreID,
      EcommerceDeliveryConfigId,
      enable,
      allowCollection,
      enablePickupBranch,
      enablePickupTime,
      requireOrderAfter,
      requireOrderBefore,
    },
  })
  return res
}

// => 取得配送方式
export const GetDelivery = async ({ shopId, start, limit, name }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceDelivery`,
    params: {
      start,
      limit,
      name,
    },
  })
  return res
}

// => 取得配送方式數量
export const GetDeliveryCount = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceDelivery/count`,
  })
  return res
}

// => 取得特定配送方式
export const FindDelivery = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommerceDelivery/${id}`,
  })
  return res
}

// => 更新配送方式
export const UpdateDelivery = async ({
  shopId,
  id,
  name,
  description,
  price,
  type,
  subType,
  senderName,
  senderCellPhone,
  returnStoreID,
  EcommerceDeliveryConfigId,
  enable,
  allowCollection,
  enablePickupBranch,
  enablePickupTime,
  requireOrderAfter,
  requireOrderBefore,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommerceDelivery/${id}`,
    data: {
      name,
      description,
      price,
      type,
      subType,
      senderName,
      senderCellPhone,
      returnStoreID,
      EcommerceDeliveryConfigId,
      enable,
      allowCollection,
      enablePickupBranch,
      enablePickupTime,
      requireOrderAfter,
      requireOrderBefore,
    },
  })
  return res
}

// => 刪除配送方式
export const DeleteDelivery = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/ecommerceDelivery/${id}`,
  })
  return res
}

export const CreateDeliveryThirdPartyConfig = async ({
  shopId,
  type,
  merchantID,
  hashKey,
  hashIV,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceDeliveryConfig`,
    data: {
      type,
      mode: configuration('ecommerceCVSMode'),
      config: {
        merchantID,
        hashKey,
        hashIV,
      },
    },
  })
  return res
}

export const ReadDeliveryThirdPartyConfig = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    url: `/${shopId}/ecommerceDeliveryConfig${id}`,
  })
  return res
}

export const DeleteDeliveryThirdPartyConfig = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/ecommerceDeliveryConfig/${id}`,
  })
  return res
}

export const GetDeliveryThirdPartyConfigs = async ({
  shopId,
  name,
  start,
  limit,
}) => {
  const res = await admin2({
    url: `/${shopId}/ecommerceDeliveryConfig`,
    params: {
      limit,
      start,
      name,
    },
  })
  return res
}

export const GetDeliveryThirdPartyConfigsCount = async ({
  shopId,
  name,
}) => {
  const res = await admin2({
    url: `/${shopId}/ecommerceDeliveryConfig/count`,
    params: {
      name,
    },
  })
  return res
}

export const GetCVSMap = async ({
  shopId,
  EcommerceDeliveryConfigId,
  clientCompleteURL,
  subType,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommerceDeliveryEcpayMap`,
    data: {
      ecommerceDeliveryConfigId: EcommerceDeliveryConfigId,
      clientCompleteURL,
      subType,
      device: 0,
    },
  })
  return res
}

export const GetSelectCVS = async ({
  shopId,
  token,
}) => {
  const res = await admin2({
    url: `/${shopId}/ecommerceDeliveryEcpayMap`,
    params: {
      token,
    },
  })
  return res
}
