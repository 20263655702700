<template>
  <div class="service-category-setting">
    <PageTitle
      title="運送方式設定"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'EcommerceParameterSetting' })"
    />
    <div class="mb-[24px]">
      <BasicTabs v-model="activeTab" :tabs="tabsOptions" />
    </div>

    <FiltersContainer>
      <div v-show="activeTab === 'configList'">
        <el-input
          v-model="nameSearch"
          clearable
          placeholder="請輸入運送方式名稱"
          @keypress.enter.native="refresh"
          @clear="refresh"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            @click="refresh"
          />
        </el-input>
      </div>
    </FiltersContainer>

    <div v-show="activeTab === 'configAPI'" class="delivery-config-tab">
      <DeliveryThirdPartySetting @refresh="refresh" />
    </div>

    <div v-show="activeTab === 'configList'">
      <section>
        <div class="mb-[24px]">
          <div class="flex justify-end">
            <el-button class="min-w-[100px]" type="primary" @click="openDialog('create')">新增</el-button>
          </div>
        </div>
        <el-table
          v-loading="loading"
          :data="productCategoryList"
          empty-text="暫無數據"
        >
          <EmptyBlock slot="empty" />

          <el-table-column prop="name" label="名稱" align="center" />
          <el-table-column prop="description" label="敘述" align="center" />
          <el-table-column prop="price" label="運費原價" align="center" />
          <el-table-column prop="type" label="運送方式" align="center">
            <template slot-scope="scope">
              <div>
                {{ deliveryTypesConfig(scope.row.type) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="110" align="center">
            <template slot-scope="scope">
              <TableEditBtnGroup
                @edit="openDialog('update'),
                       syncFormData(scope.row),
                       (selectRow = scope.row),
                       findServiceCategory()"
                @delete=";(deleteDialog = true), (selectRow = scope.row)"
              />
            </template>
          </el-table-column>
        </el-table>

        <Pagination
          :curPage.sync="tableOptions.page"
          :pageLimit="tableOptions.pageLimit"
          :total="productCategoryCount"
          @pageChange="refresh"
        />
      </section>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <section>
        <!-- ANCHOR 新增/編輯運送方式表單 -->
        <el-form
          ref="form"
          :model="formData"
          label-position="top"
          :rules="formRules"
        >
          <el-form-item label="運送方式" prop="type">
            <label slot="label" class="el-form-item__label">
              <span>運送方式</span>
              <span class="text-sub text-gray-80 font-normal">（設定更多運送方式，請至「串接設定」）</span>
            </label>
            <span v-if="disableCVSField" class="form-item-value">{{ observeType.name }}</span>
            <el-select v-else :value="observeType.value" placeholder="請選擇" @change="observeType = $event">
              <el-option
                v-for="(type, index) in allDeliveryTypes"
                :key="`type-${index}`"
                :label="type.name"
                :value="type.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="啟用運送方式" prop="enable">
            <el-switch
              v-model="formData.enable"
              inactive-text="關閉"
              active-text="開啟"
            />
          </el-form-item>
          <el-form-item v-if="showECPayField" label="物流類型" prop="subType">
            <span v-if="disableCVSField" class="form-item-value">{{ cvsTypesName[formData.subType] }}</span>
            <el-select
              v-else
              v-model="formData.subType"
              placeholder="請選擇物流類型"
              :disabled="disableCVSField"
              @change="formData.returnStoreID = ''"
            >
              <el-option
                v-for="(type, index) in cvsOptions"
                :key="`type-${index}`"
                :label="type.name"
                :value="type.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="showECPayField" label="寄件人姓名" prop="senderName">
            <span v-if="disableCVSField" class="form-item-value">{{ formData.senderName }}</span>
            <el-input
              v-else
              v-model="formData.senderName"
              placeholder="中文 2-5 字；英文 4-10 字"
              :disabled="disableCVSField"
            />
          </el-form-item>
          <el-form-item v-if="showECPayField" label="寄件人電話" prop="senderCellPhone">
            <span v-if="disableCVSField" class="form-item-value">{{ formData.senderCellPhone }}</span>
            <el-input
              v-else
              v-model="formData.senderCellPhone"
              placeholder="09 開頭手機號碼、限10碼"
              :disabled="disableCVSField"
            />
          </el-form-item>
          <el-form-item v-if="showECPayField" label="退貨門市代號" prop="returnStoreID">
            <span v-if="disableCVSField" class="form-item-value">{{ formData.returnStoreID || '退回原寄貨門市' }}</span>
            <CvsSelect
              v-else
              v-model="formData.returnStoreID"
              label="退貨門市代號"
              dataKey="returnStoreID"
              :shopId="shop"
              :cvsType="formData.subType"
              :disabled="disableCVSField"
              :configID="formData.EcommerceDeliveryConfigId"
            />
          </el-form-item>
          <el-form-item label="名稱" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="請輸入"
              maxlength="50"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="敘述" prop="description">
            <el-input
              v-model="formData.description"
              placeholder="請輸入"
              maxlength="200"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="運費原價" prop="price">
            <el-input v-model="formData.price" placeholder="請輸入" />
          </el-form-item>
          <el-form-item label="支援貨到付款" prop="allowCollection">
            <el-switch
              v-model="formData.allowCollection"
              inactive-text="關閉"
              active-text="開啟"
            />
          </el-form-item>
          <el-form-item v-if="useBranch && formData.type === 'null'" prop="enablePickupBranch">
            <template slot="label">
              <div class="flex items-center" style="gap: 8px">
                <p>開放客人選擇取貨門市</p>
                <el-tooltip placement="right">
                  <div slot="content" style="width: 210px">
                    開啟後在電商結帳頁面將顯示「取貨門市」與「取貨時間」欄位，讓客人可以選擇什麼時間點要前往哪個門市取貨。( 如要設定取貨門市，可前往「基本參數設定」→ 「門市設定」)
                  </div>
                  <span class="material-icons">help_outline</span>
                </el-tooltip>
              </div>
            </template>
            <el-switch
              v-model="formData.enablePickupBranch"
              inactive-text="關閉"
              active-text="開啟"
            />
          </el-form-item>
          <el-form-item v-if="useBranch && formData.enablePickupBranch" prop="enablePickupTime">
            <template slot="label">
              <div class="flex items-center" style="gap: 8px">
                <p>開放客人選擇取貨時間</p>
                <el-tooltip placement="right">
                  <div slot="content" style="width: 210px">
                    是否讓客人選擇取貨時間，開啟的話，電商結帳頁面將顯示此欄位，可選擇的最大時間為 365 天，關閉則不顯示此欄位
                  </div>
                  <span class="material-icons">help_outline</span>
                </el-tooltip>
              </div>
            </template>
            <el-switch
              v-model="formData.enablePickupTime"
              inactive-text="關閉"
              active-text="開啟"
            />
          </el-form-item>
          <el-form-item v-if="useBranch && formData.enablePickupBranch && formData.enablePickupTime" :show-message="false">
            <template slot="label">
              <div class="flex items-center" style="gap: 8px">
                <p>客人可選擇取貨區間設定</p>
                <el-tooltip placement="right">
                  <div slot="content" style="width: 210px">
                    設定客人購買時可選擇的取貨區間
                    ，系統將根據當下時間推算。
                    舉例：假設現在是 1/1，若設定最早取貨天數為 0 天，最晚為 7 天，則客人可選擇的取貨日期區間為 1/1 - 1/8。
                    若需限制僅能當天取貨，則可將取貨天數都設定為 0 天。
                  </div>
                  <span class="material-icons">help_outline</span>
                </el-tooltip>
              </div>
            </template>
            <div class="flex items-center" style="gap: 16px">
              <el-form-item label="最早取貨天數" prop="requireOrderAfter">
                <el-input v-model="formData.requireOrderAfter" style="width: 272px" placeholder="請輸入" />
              </el-form-item>
              <el-form-item label="最晚取貨天數" prop="requireOrderBefore">
                <el-input v-model="formData.requireOrderBefore" style="width: 272px" placeholder="請輸入" />
              </el-form-item>
            </div>
          </el-form-item>
        </el-form>
      </section>

      <div slot="footer">
        <el-button
          plain
          @click=";(showDialog = false), resetForm()"
        >
          返回
        </el-button>
        <el-button type="primary" @click="dialogConfirm">
          {{
            dialogType === 'create' ? '新增' : '儲存'
          }}
        </el-button>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteServiceCategory(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, isDigitRules, rangeRules, phoneRules, CVSSenderRules } from '@/validation/index'
import PageTitle from '@/components/Title/PageTitle.vue'
import DeliveryThirdPartySetting from './DeliveryThirdPartySetting.vue'
import CvsSelect from './CvsSelect.vue'
import {
  CreateDelivery,
  GetDelivery,
  FindDelivery,
  GetDeliveryCount,
  UpdateDelivery,
  DeleteDelivery,
  GetDeliveryThirdPartyConfigs,
  GetDeliveryThirdPartyConfigsCount,
} from '@/api/ecommerce/delivery'

import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import {
  deliveryTypesName,
  deliveryTypesList,
  cvsTypesList,
  cvsTypesName,
  deliveryConfigOptions,
  deliveryConfigOptionsName,
} from '@/config/ecommerce'
import { omit } from 'lodash'

// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { checkUserFeature } from '@/store/modules/permission'

export default {
  name: 'DeliverySetting',
  components: { DeleteDialog, EmptyBlock, PageTitle, DeliveryThirdPartySetting, CvsSelect },
  data: () => ({
    loading: false,
    nameSearch: '',
    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    customDeliveryConfigs: [],
    productCategoryList: [],
    productCategoryCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    formData: {
      id: '',
      name: '',
      description: '',
      price: '',
      type: '',
      subType: '',
      senderName: '',
      senderCellPhone: '',
      returnStoreID: '',
      allowCollection: true,
      enable: true,
      EcommerceDeliveryConfigId: '',
      enablePickupBranch: false,
      enablePickupTime: false,
      requireOrderAfter: null,
      requireOrderBefore: null,
    },
    cvsOptions: cvsTypesList,
    cvsTypesName: cvsTypesName,
    formRules: {
      name: [noEmptyRules()],
      order: [noEmptyRules('請輸入排序'), isDigitRules(), rangeRules()],
      description: [noEmptyRules()],
      price: [noEmptyRules(), isDigitRules(), rangeRules()],
      type: [noEmptyRules()],
      subType: [noEmptyRules()],
      senderName: [noEmptyRules(), CVSSenderRules()],
      senderCellPhone: [noEmptyRules(), phoneRules()],
      requireOrderAfter: [noEmptyRules(), rangeRules(0, 365), isDigitRules()],
      requireOrderBefore: [noEmptyRules(), rangeRules(0, 365), isDigitRules()],
    },
    activeTab: 'configList',
    tabsOptions: [
      {
        value: 'configList',
        label: '運送方式設定',
      },
      {
        value: 'configAPI',
        label: '串接設定',
      },
    ],
  }),
  computed: {
    ...mapGetters(['shop', 'userFeatures', 'userPlanFeature']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增運送方式',
        update: '編輯運送方式',
      })
    },
    allDeliveryTypes () {
      const { customDeliveryConfigs } = this
      const customOptions = customDeliveryConfigs.map(item => ({
        name: `${deliveryConfigOptionsName[item.type]}(${item.config.merchantID})`,
        value: item.id,
      }))
      return deliveryTypesList.concat(customOptions)
    },
    observeType: {
      get () {
        const { type, EcommerceDeliveryConfigId: configID } = this.formData
        const optBasic = this.allDeliveryTypes.find(item => item.value === type)
        const optCustom = this.allDeliveryTypes.find(item => item.value === configID)
        return optBasic || optCustom || { value: '' }
      },
      set (value) {
        const customOption = this.customDeliveryConfigs.find(item => item.id === value)
        if (!customOption) {
          this.formData.type = value
          this.formData.EcommerceDeliveryConfigId = ''
        } else {
          this.formData.type = customOption.type
          this.formData.EcommerceDeliveryConfigId = value
        }
      },
    },
    isUseECPay () {
      const { type: DeliveryType } = this.formData
      return DeliveryType === deliveryConfigOptions.ecpay
    },
    // 選擇綠界超商店到店物流，才顯示的欄位
    showECPayField () {
      return this.isUseECPay
    },
    // 編輯資料時，超商店到店不允許編輯
    disableCVSField () {
      if (this.dialogType !== 'update') return false
      return true
    },
    useBranch () {
      return this.checkAction('admin.branch.page')
    },
    submitData () {
      const {
        id, type, name, description, price, enable, allowCollection, returnStoreID,
        enablePickupBranch, enablePickupTime, requireOrderAfter, requireOrderBefore,
      } = this.formData
      if (this.dialogType === 'update') {
        const data = { id, name, description, price, enable, allowCollection }
        if (type !== 'null') return data
        if (!enablePickupBranch) return { ...data, enablePickupBranch, enablePickupTime: false }
        return {
          ...data,
          enablePickupBranch,
          enablePickupTime,
          requireOrderAfter: Number.isNaN(requireOrderAfter) ? undefined : requireOrderAfter,
          requireOrderBefore: Number.isNaN(requireOrderBefore) ? undefined : requireOrderBefore,
        }
      }
      if (!this.isUseECPay) {
        const data = { id, type, name, description, price, enable, allowCollection }
        if (type !== 'null') return data
        if (!enablePickupBranch) return { ...data, enablePickupBranch }
        return {
          ...data,
          enablePickupBranch,
          enablePickupTime,
          requireOrderAfter: Number.isNaN(requireOrderAfter) ? undefined : requireOrderAfter,
          requireOrderBefore: Number.isNaN(requireOrderBefore) ? undefined : requireOrderBefore,
        }
      }

      const data = omit(this.formData, [
        'enablePickupBranch', 'enablePickupTime', 'requireOrderAfter', 'requireOrderBefore',
      ])

      return { ...data, returnStoreID: returnStoreID || undefined }
    },

  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },

    deliveryTypesConfig (type) {
      return deliveryTypesName[type] || deliveryConfigOptionsName[type]
    },

    async refresh () {
      this.loading = true
      await this.getDelivery()
      await this.getDeliveryCount()
      await this.getAllDeliveryConfigs()
      this.loading = false
    },

    syncFormData (row) {
      this.formData = JSON.parse(JSON.stringify(row))
      // 新增欄位舊資料相容處理
      if (row.enable === null) {
        this.formData.enable = true
      }
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        id: '',
        name: '',
        description: '',
        price: '',
        type: '',
        subType: '',
        senderName: '',
        senderCellPhone: '',
        returnStoreID: '',
        allowCollection: true,
        enable: true,
        EcommerceDeliveryConfigId: '',
      }
    },

    async dialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') this.createDelivery()
      if (type === 'update') this.updateDelivery()
      this.showDialog = false
    },

    //= > 取得產品類別
    async getDelivery () {
      this.loading = true
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, error] = await GetDelivery({
        shopId: this.shop,
        start: startIndex,
        limit,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryList = res
    },
    async createDelivery () {
      this.loading = true
      const [res, error] = await CreateDelivery({
        shopId: this.shop,
        ...this.submitData,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('新增成功')
    },

    async findServiceCategory () {
      const [res, error] = await FindDelivery({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (error) return this.$message.error(error)
      console.log(res)
    },

    async deleteServiceCategory () {
      this.loading = true
      const [res, error] = await DeleteDelivery({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('刪除成功')
    },
    async updateDelivery () {
      this.loading = true
      const [res, error] = await UpdateDelivery({
        shopId: this.selectRow.ShopId,
        ...this.submitData,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('更新成功')
    },

    async getDeliveryCount () {
      this.loading = true
      const [res, error] = await GetDeliveryCount({
        shopId: this.shop,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryCount = res.count
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
    async getAllDeliveryConfigs () {
      const [res, error] = await GetDeliveryThirdPartyConfigsCount({
        shopId: this.shop,
      })
      if (error) return this.$message.error(error)

      const [configs, error1] = await GetDeliveryThirdPartyConfigs({
        shopId: this.shop,
        limit: res.count,
        start: 0,
      })
      if (error1) return this.$message.error(error1)

      this.customDeliveryConfigs = configs
    },
  },
}
</script>

<style scoped lang="postcss">
.delivery-config-tab {
  @apply mt-[56px];
}

.form-item-value {
  @apply text-gray-100 text-sub;
}

</style>
